<template>
  <div>
    <KTCard v-if="companyForm" title="Company Details">
      <template v-slot:toolbar>
        <b-button-group size="sm">
          <b-button
            v-if="readOnly"
            @click="onEdit()"
            variant="secondary"
            size="sm"
          >
            EDIT
          </b-button>
          <b-button v-if="!readOnly" @click="cancelEdit" variant="secondary"
            >CANCEL</b-button
          >
          <b-button v-if="!readOnly" @click="onSave()" variant="primary"
            >SAVE</b-button
          >
        </b-button-group>
      </template>
      <template v-slot:body>
        <div>
          <b-row align-v="stretch">
            <!-- Company Details -->
            <b-col sm="6">
              <b-card title-tag="h6" title="Details" class="mb-4">
                <base-input
                  v-model="companyForm.company_name"
                  label="Name"
                  :disabled="true"
                  :validator="$v.companyForm.company_name"
                  :messages="localMessages"
                ></base-input>
                <base-input
                  v-model="companyForm.ssm"
                  label="Registration No"
                  :disabled="true"
                  :validator="$v.companyForm.ssm"
                  :messages="localMessages"
                ></base-input>
              </b-card>
            </b-col>
            <!-- END Company Details -->

            <!-- Person In Charge -->
            <b-col sm="6">
              <b-card
                class="mb-4"
                title-tag="h6"
                sub-title-tag="p"
                title="Person In Charge"
                sub-title="The details in this section will only be used for verification purposes."
              >
                <base-input
                  v-model="companyForm.pic_name"
                  label="Name"
                  :disabled="readOnly"
                  :validator="$v.companyForm.pic_name"
                  :messages="localMessages"
                ></base-input>
                <base-input
                  v-model="companyForm.pic_email"
                  label="Email"
                  :disabled="readOnly"
                  :validator="$v.companyForm.pic_email"
                  :messages="localMessages"
                ></base-input>
                <base-input
                  v-model="companyForm.pic_phone_number"
                  label="Phone No"
                  :tooltipData="phoneNumberTooltip"
                  :disabled="readOnly"
                  :validator="$v.companyForm.pic_phone_number"
                  :messages="localMessages"
                ></base-input>
              </b-card>
            </b-col>
            <!-- END Person In Charge -->
          </b-row>
          <b-card class="mb-4" title-tag="h6" title="Company Account Details">
            <b-row>
              <b-col>
                <base-input
                  type="select"
                  v-model="companyForm.bank_name"
                  label="Bank Name"
                  placeholder="Please select"
                  :options="BankListOptions"
                  :disabled="readOnly"
                  :validator="$v.companyForm.bank_name"
                  :messages="localMessages"
                ></base-input>
                <base-input
                  v-if="companyForm.bank_name === 'Other'"
                  v-model="companyForm.others"
                  type="text"
                  attribute="Bank Name"
                  label="Please state your bank name"
                  :validator="$v.companyForm.others"
                  :messages="localMessages"
                ></base-input>
              </b-col>
              <b-col>
                <base-input
                  v-model="companyForm.bank_details"
                  label="Account Number"
                  :disabled="readOnly"
                  :validator="$v.companyForm.bank_details"
                  :messages="localMessages"
                ></base-input>
                <p class="mb-3">
                  Information to be used for refund purposes (if any). Please
                  ensure that all information provided are accurate.
                </p>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="mb-4" title-tag="h6" title="Company Address">
            <b-row>
              <b-col>
                <base-input
                  v-model="companyForm.address_line_1"
                  label="Address 1"
                  :disabled="readOnly"
                  :validator="$v.companyForm.address_line_1"
                  :messages="localMessages"
                ></base-input>
                <base-input
                  v-model="companyForm.address_line_2"
                  label="Address 2"
                  :disabled="readOnly"
                  :validator="$v.companyForm.address_line_2"
                  :messages="localMessages"
                ></base-input>
                <base-input
                  v-model="companyForm.address_line_3"
                  label="Address 3"
                  :disabled="readOnly"
                  :validator="$v.companyForm.address_line_3"
                  :messages="localMessages"
                ></base-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <base-input
                  v-model="companyForm.postcode"
                  label="Postcode"
                  max-length="5"
                  :disabled="readOnly"
                  :validator="$v.companyForm.postcode"
                  :messages="localMessages"
                ></base-input>
                <base-input
                  type="select"
                  v-model="companyForm.city"
                  label="City"
                  :options="ParsedCities"
                  :disabled="readOnly"
                  :validator="$v.companyForm.city"
                  :messages="localMessages"
                ></base-input>
              </b-col>
              <b-col>
                <base-input
                  type="select"
                  v-model="companyForm.state"
                  label="State"
                  :options="ParsedStates"
                  :disabled="readOnly"
                  :validator="$v.companyForm.state"
                  :messages="localMessages"
                ></base-input>
                <base-input
                  type="select"
                  v-model="companyForm.country"
                  label="Country"
                  :options="ParsedCountries"
                  :disabled="readOnly"
                  :validator="$v.companyForm.country"
                  :messages="localMessages"
                ></base-input>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";

import { validationMixin } from "vuelidate";
import SystemHelper from "@/core/services/systemhelper.service";
import {
  GET_COMPANY_UPDATE,
  UPDATE_COMPANY,
} from "@/core/services/store/modules/clientadmin/administration.module";
// import UserService from "@/core/services/user.service";
import {
  maxLength,
  email,
  numeric,
  minLength,
  required,
  requiredIf,
} from "vuelidate/lib/validators";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/modules/htmlclass.module";

export default {
  mixins: [validationMixin],
  components: {},
  async mounted() {
    this.isLoading = true;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Client Admin" },
      { title: "Company" },
      { title: "Details" },
    ]);

    // let userData = JSON.parse(UserService.getUserData());
    // let payload = {
    //   company_id: userData.company.company_id,
    // };
    let payload = {};

    await this.$store.dispatch(GET_COMPANY_UPDATE, payload).then(() => {
      this.companyForm = this.Company;

      // remove files
      if (this.companyForm.com_doc_one) {
        delete this.companyForm.com_doc_one;
      }
      if (this.companyForm.com_doc_two) {
        delete this.companyForm.com_doc_two;
      }
      if (this.companyForm.com_doc_three) {
        delete this.companyForm.com_doc_three;
      }
      if (this.companyForm.com_doc_four) {
        delete this.companyForm.com_doc_four;
      }

      this.isLoading = false;
    });
  },
  validations: {
    companyForm: {
      company_name: { required },
      bank_name: { required },
      bank_details: { required },
      others: {
        required: requiredIf(function() {
          return this.companyForm.bank_name === "Other";
        }),
      },
      ssm: { required },
      pic_name: { required },
      pic_phone_number: { required },
      pic_email: { required, email },
      address_line_1: { required, minLength: minLength(5) },
      address_line_2: { minLength: minLength(5) },
      address_line_3: { minLength: minLength(5) },
      country: { required },
      state: { required },
      city: { required },
      postcode: {
        required,
        numeric,
        minLength: minLength(5),
        maxLength: maxLength(5),
      },
    },
  },
  computed: {
    BankListOptions() {
      const bank = this.BankList;
      let options = [];
      if (bank.length) {
        bank.forEach((bank) => {
          options.push({ label: bank.name, value: bank.name });
        });
      }
      options.push({ label: "Other", value: "Other" });
      return options;
    },
    BankList() {
      const bank = this.SystemConstants.bankListReference;
      if (Array.isArray(bank) && bank.length) {
        return bank;
      } else {
        return [];
      }
    },
    ParsedCities() {
      if (Object.keys(this.SystemConstants).length) {
        let states = this.SystemConstants.countryStateCityRelation[0].states;

        if (this.companyForm.state) {
          let state = states.find((s) => s.state_id === this.companyForm.state);

          const parsedCity = [];
          state.cities.forEach((city) => {
            parsedCity.push({ value: city.city_id, label: city.city });
          });
          return parsedCity;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    ParsedStates() {
      if (Object.keys(this.SystemConstants).length) {
        let states = this.SystemConstants.countryStateCityRelation[0].states;
        let parsedStates = [];

        states.forEach((state) => {
          parsedStates.push({ value: state.state_id, label: state.state });
        });
        return parsedStates;
      } else {
        return [];
      }
    },
    ParsedCountries() {
      if (Object.keys(this.SystemConstants).length) {
        let countries = this.SystemConstants.country;
        let parsedCountries = [];
        countries.forEach((country) => {
          parsedCountries.push({ value: country.value, label: country.key });
        });
        return parsedCountries;
      } else {
        return [];
      }
    },
    SystemConstants() {
      const constants = this.$store.state.ui.systemConstants;
      if (
        typeof constants == "object" &&
        constants !== null &&
        Object.keys(constants).length
      ) {
        return constants.data;
      } else {
        return {};
      }
    },
    Company() {
      const company = this.$store.state.clientadmin_administration
        .companyDetails;
      if (typeof company === "object" && company) {
        return company;
      } else return {};
    },
  },
  data() {
    return {
      phoneNumberTooltip: {
        icon: "fa fa-info-circle",
        message: "Example format: 019XXXXXXX",
      },
      readOnly: true,
      companyForm: null,
      isLoading: false,
      form: {},
      filter: "",
      currentPage: 1,
      fields: [],
    };
  },

  methods: {
    onEdit() {
      this.readOnly = !this.readOnly;
    },
    cancelEdit() {
      this.$store.dispatch(GET_COMPANY_UPDATE).then(() => {
        this.$v.companyForm.$reset();
        this.companyForm = this.Company;
        this.readOnly = true;
      });
    },
    // onSave() {
    //   this.$v.companyForm.$touch();
    //   if (this.$v.companyForm.$anyError) {
    //     return;
    //   } else {
    //     this.readOnly = true;
    //
    //     if (this.companyForm.bank_name === "Other") {
    //       this.companyForm.bank_name = this.companyForm.others;
    //     }
    //
    //     let userData = JSON.parse(UserService.getUserData());
    //     const payload = this.companyForm;
    //     const payloadGet = {
    //       company_id: userData.company.company_id,
    //     };
    //
    //     SystemHelper.confirmationDialogHandler(
    //       { title: "Confirmation", html: "Update company information?" },
    //       { action: UPDATE_COMPANY, payload: payload },
    //       [
    //         {
    //           action: GET_COMPANY_UPDATE,
    //           payload: payloadGet,
    //         },
    //       ],
    //       null
    //     );
    //   }
    // },

    onSave() {
      // validate inputs
      this.$v.companyForm.$touch();
      if (this.$v.companyForm.$anyError) {
        return;
      } else {
        this.readOnly = true;

        if (this.companyForm.bank_name === "Other") {
          this.companyForm.bank_name = this.companyForm.others;
        }

        const swalParam = {
          title: "UPDATE COMPANY",
          showCancelButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          closeOnClickOutside: false,
          confirmButtonText: "Confirm",
          html: "Are you sure?",
          customClass: {
            container: "confirmation-swal",
            header: "bg-primary",
            confirmButton: "btn btn-sm btn-primary ml-3",
            cancelButton: "btn btn-sm btn-secondary ml-3",
            input: "form-control",
          },
        };
        this.$swal(swalParam).then((res) => {
          this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
          if (res.value) {
            this.$store
              .dispatch(UPDATE_COMPANY, this.companyForm)
              .then((res) => {
                if (res.response_code == 2100) {
                  // popup success
                  SystemHelper.basicSwalHandler(
                    "Info",
                    "The submission is successfully sent for review"
                  );

                  // refresh form
                  this.$store.dispatch(GET_COMPANY_UPDATE).then((data) => {
                    if (data.response_code == 2100) {
                      this.companyForm = this.Company;
                      this.$v.companyForm.$reset();
                      this.$store.dispatch(
                        REMOVE_BODY_CLASSNAME,
                        "page-loading"
                      );
                    } else {
                      SystemHelper.errorHandler(data, null, "error");
                      this.$store.dispatch(
                        REMOVE_BODY_CLASSNAME,
                        "page-loading"
                      );
                    }
                  });
                } else {
                  SystemHelper.errorHandler(res, null, "error");
                  this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                }
              });
          } else {
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          }
        });
      }
    },
  },
};
</script>
